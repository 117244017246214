import React, { useEffect, useState } from "react";
import queryString from "query-string";
import axios from "axios";
import Layout from "../components/layout";
import { useStaticQuery, graphql } from "gatsby";
import Grid from "@quandoo-dev/cookbook/lib/grid";
import { Box } from "@quandoo-dev/cookbook/lib/layout";
import { Heading, Body } from "@quandoo-dev/cookbook/lib/typography";
import MetaInfo from "../components/post/meta-info";
import RichText from "../components/rich-text";
import MerchantEmbed from "../components/merchant-embed";
import Tags from "../components/post/tags";
import Share from "../components/post/share";
import PostInRow from "../components/cards/postInRow";
import Divider from "@quandoo-dev/cookbook/lib/divider";

interface PostPreviewProps {
  location: { search: string };
}

interface IUseState {
  data: any;
}

const PostPreview: React.FC<PostPreviewProps> = props => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            previewData {
              url
              token
            }
          }
        }
      }
    `
  );

  const [data, setData] = useState<IUseState>();

  const {
    location: { search: search },
  } = props;
  const { id } = search ? queryString.parse(search) : { id: null };

  const query = `
  query($preview: Boolean) {
    post(preview: $preview, id: "${id}"){
      sys {
        publishedAt
      }
      title
      slug
      publishDate
      timeToRead
      description
      locale {
        slug
      }
      heroDesktop {
          description
          asset {
            url
         }
      }
      heroMobile {
        description
        asset {
          url
       }
      }
      previewImage {
        description
        asset {
          url
        }
      }
      author {
        firstName
        lastName
        slug
        locale {
          slug
        }
        profileImage {
          url
        }
      }
      category {
        slug
      }
      contentCollection(limit: 100) {
        items {
          ... on PostCopy {
            title
            richBody {
              json
            }
          }
          ... on Image {
            caption
            asset {
              url
            }
          }
          ... on TopicPostEmbed {
            code 
          }
          ... on PostMerchantEmbed {
            merchantName
            addressText
            openingHours
            priceCategory
            linkToBook
            richDescription {
              json
            }
            instagramPostUrl
            merchantImage {
              url 
            }
            address {
              lon
              lat
            }
            ctaText
            customSectionTitle
            customSectionText {
              json
            }
          }
        }
      }
      tagsCollection {
        items {
          ...on Tag {
            name
            slug
            locale {
              name
              slug
            }
          }
        }
      }
      relatedPostsCollection {
        items {
          ...on Post {
            slug
            title
            timeToRead
            locale {
              slug
            }
            category {
              name
              slug
            }
            previewImage {
              description
              asset {
                url
              }
            }
          }
        }
      }
    }
  }
  `;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: site.siteMetadata.previewData.token,
    },
    data: {
      query: query,
      variables: { preview: true },
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .post(site.siteMetadata.previewData.url, {}, config)
        .then(response => {
          if (response.status === 200) {
            setData(response.data);
          }
        })
        .catch(err => console.error(err));
    };
    fetchData();
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  const {
    data: {
      post: {
        title,
        publishDate,
        timeToRead,
        author,
        locale,
        slug,
        previewImage,
        heroDesktop,
        heroMobile,
        description,
        category,
        contentCollection,
        tagsCollection,
        relatedPostsCollection,
      },
    },
  } = data;

  const date = new Date(publishDate).toLocaleString(locale.languageCode, {
    day: "numeric",
    month: "numeric",
    year: "2-digit",
  });

  const url = `${locale.domain}/${category.slug}/${slug}`;

  const staffPicks = relatedPostsCollection.items.map((post, index) => (
    <Grid.Item columns={[12, 12, 6, 3]} key={index}>
      <PostInRow
        post={post}
        staticImage={post.previewImage.asset.url}
      ></PostInRow>
    </Grid.Item>
  ));

  //return <BlogPost data={{ contentfulPost: data.fields }} />;
  return (
    <Layout locale={locale.slug}>
      <Box
        style={{ overflow: "hidden" }}
        height={["182px", "168px", "290px", "386px"]}
      >
        <img
          style={{ width: "100%", borderRadius: "8px" }}
          src={heroDesktop.asset.url}
        />
      </Box>
      <Box
        maxWidth={{ _: "auto", md: "770px", lg: "800px" }}
        mx="auto"
        mt="xl"
        as="article"
        mb="xxxl"
      >
        <MetaInfo
          author={author}
          publishDate={date}
          timeToRead={timeToRead}
          staticImage={author.profileImage.url}
          country={locale.slug}
        />
        <Heading size={1} mt="xl" mb="md">
          {title}
        </Heading>
        <Body size={["lg", "lg", "lg", "xl"]} mb="lg">
          {description}
        </Body>
        {contentCollection.items.map((c, index) => {
          if (c.hasOwnProperty("title")) {
            return <RichText key={index} document={c.richBody.json} />;
          } else if (c.hasOwnProperty("caption")) {
            return (
              <div>
                <img key={index} style={{ width: "100%" }} src={c.asset.url} />
                <Body as="figcaption" mt="xxs" mb="md" size="xs">
                  {c.caption}
                </Body>
              </div>
            );
          } else if (c.hasOwnProperty("merchantName")) {
            return (
              <MerchantEmbed
                key={index}
                {...c}
                merchantImage={null}
                staticImage={c.merchantImage}
                country={locale.slug}
              />
            );
          } else if (c.hasOwnProperty("code")) {
            const markup = { __html: c.code };
            return <div dangerouslySetInnerHTML={markup}></div>;
            //return <span>{JSON.stringify(c)}</span>;
          }
        })}
        <Box
          maxWidth={{ _: "auto", md: "770px", lg: "800px" }}
          mx="auto"
          pt="lg"
        >
          <Tags tags={tagsCollection.items} country={locale.slug} />
          <Box mt="xl">
            <Share
              locale={locale}
              title={title}
              description={description}
              url={url}
              country={locale.slug}
            />
          </Box>
        </Box>
      </Box>
      <Grid.Row mb="xxxl">
        <Grid.Item columns={12}>
          <Heading size={4} color="secondary" mt="xxxl">
            Staff Picks
          </Heading>
          <Divider color="#D8D8D8" mt="xxs" mb="xs" />
        </Grid.Item>
        {staffPicks}
      </Grid.Row>
    </Layout>
  );
};

export default PostPreview;
